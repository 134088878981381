import { faTimesCircle, faList, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useLayoutEffect } from 'react';
import { useProductRequests } from '../../helpers/ProductRequests';
import { useHistory, useLocation } from 'react-router-dom';
import { HeaderAltMobile } from '../../styles/Header';

export default function PopUp({ open, setOpen }) {
  const { getCategories } = useProductRequests();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null); // Ana kategori seçimi
  const [openSubCategories, setOpenSubCategories] = useState({}); // Alt kategorilerin açık olup olmadığını kontrol etmek için
  const history = useHistory();
  const location = useLocation(); // Current location (URL)

  // Kategorileri API'den al
  useLayoutEffect(() => {
    if (categories.length === 0) {
      getCategories()
        .then((response) => {
          setCategories(response.data.data);
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  }, [categories]);

  const closePopUp = (rt) => {
    setOpen(false);
    setSelectedCategory(null);
    history.push(rt);
  };

  // Alt kategorileri açma / kapama işlevi
  const toggleSubCategoryVisibility = (categorySlug, subCategorySlug = null) => {
    setOpenSubCategories((prev) => {
      const updated = { ...prev };

      // Eğer sadece ana kategoriye tıklandıysa
      if (!subCategorySlug) {
        // Aynı kategori açıldıysa, kapat, yoksa sadece onu aç
        updated[categorySlug] = !updated[categorySlug];
        // Diğer ana kategoriler kapanmalı
        Object.keys(updated).forEach((key) => {
          if (key !== categorySlug) updated[key] = false;
        });
      } else {
        // Eğer alt kategoriye tıklandıysa
        if (updated[categorySlug]) {
          // Alt kategori zaten açılmışsa, açma
          updated[subCategorySlug] = !updated[subCategorySlug];
        }
      }

      return updated;
    });
  };

  // URL'ye göre aktif kategori kontrolü
  const isActive = (slug) => {
    return location.pathname.includes(slug); // Eğer URL slug içeriyorsa aktif sayılır
  };

  return (
    <HeaderAltMobile>
      <button className="close-btn" onClick={() => setOpen(false)}>
        <FontAwesomeIcon icon={faTimesCircle} />
      </button>
      <div style={{ marginTop: 70 }}>
        <h1 style={{ color: '#000', marginLeft: 40 }}>Kategoriler</h1>
        <ul className='mobile-category-list'>
          {categories != null && categories.length > 0 ? (
            <>
              {categories.map((category) => (
                <li
                  className='mobile-category-items'
                  key={'ctgm' + category.slug}
                >
                  <button className={`mobile-category-items-button ${isActive(category.slug) ? 'active' : ''}`}>

                    <div>
                      <span
                        onClick={() => {
                          setSelectedCategory(category.slug); // Ana kategoriyi seç
                          closePopUp('/kategori/' + category.slug);
                        }}
                        style={{ width: '100%' }}
                      >
                        {category.name}
                      </span>

                      {/* Eğer alt kategori varsa, ikon göster */}
                      {category.subGroups && category.subGroups.length > 0 && (
                        <div 
                          onClick={() => toggleSubCategoryVisibility(category.slug)}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background:'lightgray',
                            marginRight:10,
                            paddingRight:10,
                            width:60,
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              width: 20,
                              paddingRight: 10,
                              textAlign: 'right',
                              minHeight: 20,
                              marginLeft: 'auto',
                              cursor: 'pointer',
                            }}
                            icon={openSubCategories[category.slug] ? faChevronUp : faChevronDown}
                          />
                        </div>
                      )}
                    </div>
                  </button>

                  {/* Eğer kategori seçildiyse ve alt kategoriler açıksa, alt kategorileri göster */}
                  {openSubCategories[category.slug] && category.subGroups && (
                    <ul className='mobile-category-sub-items' style={{ 
                        paddingLeft: '20px',
                        paddingBottom:'5px',
                        transition: 'max-height 0.3s ease-out', // Geçiş animasyonu
                        overflow: 'hidden'  // Kapanınca içeriği gizle
                      }}>
                      {category.subGroups.map((subGroup) => (
                        <li
                          className='mobile-category-sub-item'
                          key={'subcat' + subGroup.slug}
                        >
                          <button className={`mobile-category-sub-item-button ${isActive(subGroup.slug) ? 'active' : ''}`}>
                            <div>
                              <span
                                onClick={() => {
                                  setSelectedCategory(subGroup.slug); // Alt kategoriyi seç
                                  closePopUp('/kategori/' + subGroup.slug);
                                }}
                              >
                                {subGroup.name}
                              </span>

                              {/* Eğer alt kategoriye ait alt kategoriler varsa, ikon göster */}
                              {subGroup.subSubGroups && subGroup.subSubGroups.length > 0 && (
                               <div 
                               onClick={() => toggleSubCategoryVisibility(category.slug, subGroup.slug)}
                               style={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'center',
                                 background:'lightgray',
                                 marginRight:10,
                                 paddingRight:10,
                                 width:60,
                               }}>
                                  <FontAwesomeIcon
                                    style={{
                                      width: 20,
                                      paddingRight: 10,
                                      textAlign: 'right',
                                      minHeight: 20,
                                      marginLeft: 'auto',
                                      cursor: 'pointer',
                                    }}
                                    icon={openSubCategories[subGroup.slug] ? faChevronUp : faChevronDown}
                                  />
                                </div>
                              )}
                            </div>
                          </button>

                          {/* Eğer alt kategori seçildiyse ve alt-alt kategoriler açıksa, alt-alt kategorileri göster */}
                          {openSubCategories[subGroup.slug] && subGroup.subSubGroups && (
                            <ul className='mobile-category-subsub-items' style={{ 
                              paddingLeft: '20px',
                              paddingBottom:'5px',
                              transition: 'max-height 0.3s ease-out',
                              overflow: 'hidden' }}>
                              {subGroup.subSubGroups.map((subSubGroup) => (
                                <li
                                  className='mobile-category-subsub-item'
                                  key={'subsubcat' + subSubGroup.slug}
                                >
                                  <button
                                    className={`mobile-category-subsub-item-button ${isActive(subSubGroup.slug) ? 'active' : ''}`}
                                    onClick={() =>
                                      closePopUp('/kategori/' + subSubGroup.slug)
                                    }
                                  >
                                    <div>{subSubGroup.name}</div>
                                  </button>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </>
          ) : (
            <div>Yükleniyor...</div> // Kategoriler yüklenene kadar gösterilecek metin
          )}
        </ul>
      </div>
    </HeaderAltMobile>
  );
}

// document.addEventListener('DOMContentLoaded', () => {
 
//   const mainButtons = document.querySelectorAll('.mobile-category-items-button');
//   const subButtons = document.querySelectorAll('.mobile-category-sub-item-button');
//   const subSubButtons = document.querySelectorAll('.mobile-category-subsub-item-button');

  
//   [...mainButtons, ...subButtons, ...subSubButtons].forEach(button => {
//     button.addEventListener('click', (e) => {
//       const clickedButton = e.currentTarget;

    
//       [...mainButtons, ...subButtons, ...subSubButtons].forEach(btn => btn.classList.remove('active'));

      
//       let parent = clickedButton.closest('li'); // Şimdiki kategorinin li elemanını al
//       while (parent) {
//         const parentButton = parent.querySelector('button'); // İlgili butonu seç
//         if (parentButton) parentButton.classList.add('active'); // active ekle
//         parent = parent.parentElement.closest('li'); // Bir üst parent'a geç
//       }
//     });
//   });
// });