import React, { useState, useLayoutEffect } from "react";

import { useProductRequests } from "../../helpers/ProductRequests";
import { BigCategoryContainer } from "../../styles/Home";
import BigCategoryItem from "../Content/Category/BigCategoryItem";
import {
  faAngleRight,
  faArrowRight,
  faBars,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

export default function BigCategories({ open, setOpen }) {
  const { getCategories, getCampaigns } = useProductRequests();
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  React.useEffect(() => {
    if (categories.length != 0) {
      setOpen(true);
    }
  }, [categories]);

  useLayoutEffect(() => {
    if (categories.length === 0) {
      getCategories()
        .then((response) => {
          setCategories(response.data.data);
        })
        .catch((error) => {
          console.log("error", error.response);
        });
      getCampaigns()
        .then((response) => {
          setCampaigns(response.data.images);
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    }
  }, []);
  return (
    <BigCategoryContainer open={open}>
      <div
        className="head"
        onClick={() => {
          if (categories.length > 0) {
            setOpen(!open);
          }
        }}
      >
        <FontAwesomeIcon icon={faBars} /> <span>{open && "KATEGORİLER"}</span>
      </div>
      {open && (
        <div className="category-item-list">
          <div
            style={{ backgroundColor: "rgba(255,0,0,0.01)" }}
            className="category-item"
          >
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
              onClick={() => history.push(`/kampanyali-urunler`)}
            >
              <div
                style={{
                  color: "red",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <span style={{ color: "red" }}>Kampanyalı Ürünler</span>
                <FontAwesomeIcon icon={faStar} color="red" />
              </div>
            </span>
          </div>
          {categories.map((item) => (
  <div key={item.slug} className="category-item">
    {/* Ana kategori */}
    <span
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
      onClick={() => history.push(`/kategori/${item.slug}`)}
    >
      <div>{item.name}</div>
      {/* Eğer alt kategoriler varsa simge ekle */}
      {item.subGroups && item.subGroups.length > 0 && (
        <FontAwesomeIcon icon={faAngleRight} />
      )}
    </span>

    {/* Alt kategoriler */}
    {item.subGroups && item.subGroups.length > 0 && (
      <div className="category-sub-items">
        {item.subGroups.map((sub) => (
          <div key={sub.slug} className="sub-item">
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
              onClick={() => history.push(`/kategori/${sub.slug}`)}
            >
              <div>{sub.name}</div>
            
              {/* Eğer alt alt kategoriler varsa simge ekle */}
              {sub.subSubGroups && sub.subSubGroups.length > 0 && (
                <FontAwesomeIcon icon={faAngleRight} />
              )}
            </span>
           
            {/* Alt alt kategoriler */}
            {sub.subSubGroups && sub.subSubGroups.length > 0 && (
              <div className="category-subsub-items">
                {sub.subSubGroups.map((subSub) => (
                  <div key={subSub.slug} className="subsub-item">
                    <span
                      onClick={() => history.push(`/kategori/${subSub.slug}`)}
                    >
                      {subSub.name}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    )}
  </div>
))}
          {campaigns.map((item, index) => {
            return (
              <div key={"campaign-" + index} className="category-item">
                <img src={item + "?ordu=" + 5252} style={{ width: "100%" }} />
              </div>
            );
          })}
        </div>
      )}
    </BigCategoryContainer>
  );
}
