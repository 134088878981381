import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Header from "../Navigation/Header";
import BottomNav from "../Navigation/Bottom";
import "react-activity/dist/Sentry.css";
import { useLocation } from 'react-router-dom';
import {
  ProductList,
  FilterContainer,
  ProductListTitle,
} from "../../styles/Product";
import Title from "./Title";
import ProductItem from "../Product/ProductItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faHome,
  faBorderAll,
  faList,
  faUser,
  faArrowAltCircleDown,
  faArrowDown,
  faSort,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Sentry } from "react-activity";
import CategoryList from "./Category/CategoryList";
import { Button } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { HomeList } from "../../styles/Home";
import BigCategories from "../Navigation/BigCategories";

export default function Category({
  karsilastir,
  open,
  setOpen,
  search,
  setSearch,
  setSelectedBrand,
  selectedBrand,
  slug,
  getNewProducts,
  getProducts,
  getSearchedProducts,
  products,
  setProducts,
  setSort,
  sort,
}) {
  const [loading, setLoading] = useState(true);
  const [grid, setGrid] = useState(
    localStorage.getItem("productList") || "grid"
  );
  const history = useHistory();

  const listInnerRef = useRef();

  const [page, setPage] = useState(1);

  const [goTopVisibility, setGoTopVisibility] = useState(false);



  
  const onScroll = () => {
    getNewProducts(page + 1, setLoading, search, setPage);
  };

  useLayoutEffect(() => {
    if (products.length < 1) {
      setLoading(true);

      getProducts(
        selectedBrand ? {
        all: search,
        brandSlug: selectedBrand,
        sort: sort.sort,
        sortedBy: sort.sortedBy,
        count: 24,
        page: page,
      }: {
        all: search,
        sort: sort.sort,
        sortedBy: sort.sortedBy,
        count: 24,
        groupSlug: slug, 
        page: page,
      })
        .then((res) => {
          setLoading(false);
          if (res.data.data.items && res.data.data.items.length < 1) {
          } else {
            setProducts(res.data.data.items);
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [products]);

  useEffect(() => {
    setProducts([]);
  }, [slug]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (search.length > 0) {
      setProducts([]);
      getSearchedProducts(setLoading, setPage, search);
    } else {
      setPage(1);
      setProducts([]);
      getNewProducts(1, setLoading, search, setPage);
    }
  }, [search, selectedBrand]);

  useEffect(() => {
    if (sort != undefined && sort != null) {
      setPage(1);
      setProducts([]);
      getProducts(1, setLoading, search, setPage);
    }
  }, [sort]);

  return (
    <div className="unlimited-scroll" ref={listInnerRef}>
      <Header />
      <HomeList open={open} setOpen={setOpen}>
        <BigCategories open={open} setOpen={setOpen} />
        <div className="content-container">
          <FilterContainer>
            <div className="filter-container">
              <div
                className="go-back"
                onClick={() => {
                  history.push("/");
                }}
              >
                <FontAwesomeIcon icon={faHome} style={{ marginRight: 20 }} />{" "}
                Ana Sayfa
              </div>
            </div>
          </FilterContainer>

          <div className="productList-parent">
            <div className="product-sorter">
              {products.length > 0 && (
                <ProductListTitle>
                  <div className="items">
                    <Menu
                      className={"productSort"}
                      as="nav"
                      style={{ position: "relative" }}
                    >
                      <Menu.Button>
                        {" "}
                        {sort != undefined && sort != null
                          ? sort.name
                          : "Akıllı Sıralama"}{" "}
                        <FontAwesomeIcon icon={faSort} />{" "}
                      </Menu.Button>
                      <Menu.Items className="sortDrop">
                        <Menu.Item>
                          <a
                            onClick={() => {
                              setSort({
                                sort: "PRICE",
                                sortedBy: "ASC",
                                name: "Artan Fiyat",
                              });
                            }}
                          >
                            Artan fiyat
                          </a>
                        </Menu.Item>
                        <Menu.Item>
                          <a
                            onClick={() => {
                              setSort({
                                sort: "PRICE",
                                sortedBy: "DESC",
                                name: "Azalan Fiyat",
                              });
                            }}
                          >
                            Azalan fiyat
                          </a>
                        </Menu.Item>
                        {slug != undefined ? (
                          <>
                            <Menu.Item>
                              <a
                                onClick={() => {
                                  setSort({
                                    sort: "DATE",
                                    sortedBy: "DESC",
                                    name: "Önce en yeni",
                                  });
                                }}
                              >
                                Önce en yeni
                              </a>
                            </Menu.Item>

                            <Menu.Item>
                              <a
                                onClick={() => {
                                  setSort({
                                    sort: "DATE",
                                    sortedBy: "ASC",
                                    name: "Önce en eski",
                                  });
                                }}
                              >
                                Önce en eski
                              </a>
                            </Menu.Item>
                          </>
                        ) : (
                          <></>
                        )}
                      </Menu.Items>
                    </Menu>
                  </div>
                </ProductListTitle>
              )}
            </div>
            <ProductList className={` product-${grid}`} count={products.length}>
              {/*  */}

              {products.sort(karsilastir).map((product, index) => {
                return (
                  <React.Fragment key={product.id}>
                    <ProductItem product={product} />
                  </React.Fragment>
                );
              })}

              <Title
                text={
                  <>
                    {loading ? (
                      <>{/* <Sentry color={"#19477B"} size={130} /> */}</>
                    ) : products.length > 0 ? (
                      <div
                        onClick={onScroll}
                        style={{
                          color: "#000",
                          cursor: "pointer",
                          fontSize: 22,
                        }}
                      ></div>
                    ) : (
                      "Ürün Bulunamadı"
                    )}
                  </>
                }
              />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={onScroll}
                  style={{
                    display: "inline-block",
                    color: "#ffff",
                    padding: "5px 55px",
                    borderRadius: 100,
                    background: "#19477B",
                    textAlign: "center",
                    cursor: "pointer",
                    fontSize: 22,
                  }}
                >
                  {loading ? <Sentry /> : <>Daha Fazla Ürün Göster</>}
                </div> 
              </div>
            </ProductList>
          </div>
        </div>
      </HomeList>

      <BottomNav />

      {/* <Footer/> */}
    </div>
  );
}
