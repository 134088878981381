import React from 'react'
import { CategoryBoxItems } from '../../styles/Content'
import { useHistory } from 'react-router-dom';


/*****  BURADA NORMALDE KATEGORILER FILTRELENIYORDU BURAYI MARKAYA ÇEVİRDİK *******/
export default function CategoryBox({ brands }) {
    const history = useHistory(); // history hook'u burada kullanılacak
    return (
      <CategoryBoxItems>
        {brands.map((brand) => (
          <div
            key={brand.slug}
            className='category-item'
            onClick={() => history.push(`/marka/${brand.slug}`)}
          >

            <img 
              src={brand.image ?? `https://entegroid.com/sasagsm/marka/${brand.slug}.jpg`} 
              alt={brand.name}
            />
            <span>{brand.name}</span>
          </div>
        ))}
      </CategoryBoxItems>
    );
  }
